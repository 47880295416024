import Service from "../service";

const question = {
  onGetQuestion(id, accessToken) {
    return Service.get(`/question_sets/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
  onGetQuestionNotice(id, accessToken) {
    return Service.get(`/question-set-notice/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
  sendAnswer(data, accessToken) {
    return Service.post("send-answer", data, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
  getUserScore(user_id, accessToken) {
    return Service.get(`answer/get-user-score`, {
      params: { user_id: user_id },
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
};

export default question;
