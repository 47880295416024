<template>
  <div id="sideMenuMobile">
    <div v-b-toggle.sidebar-1><b-icon-list-ul></b-icon-list-ul>หมวดหมู่</div>
    <b-sidebar id="sidebar-1" class="" title="หมวดหมู่" shadow>
      <div class="px-3 py-2">
        <div class="pointer" v-for="item in category" :key="item.id">
          <a @click="categorytClick(item.id)">{{ item.name }}</a>
          <hr />
        </div>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "sideMenuMobile",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      category: (state) => state.Category.category,
    }),
  },
  created() {
    this.getCategory();
  },
  methods: {
    async getCategory() {
      await this.$store.dispatch("Category/getCategory");
    },
    async categorytClick(id) {
      this.isLoading = true;
      await this.$store.dispatch("Courses/getAllCourse", {
        category: id,
        keyword: null,
      });
      this.isLoading = false;
    },
  },
};
</script>
<style>
/*@media screen and (min-width: 992px) {*/
/*  #sideMenuMobile {*/
/*    display: none !important;*/
/*  }*/
/*}*/
</style>
