<template>
  <div class="footer mainFooter">E-learning CopyRight © 2021</div>
</template>
<script>
export default {
  name: "mainFooter",
};
</script>
<style lang="scss">
.mainFooter {
  background-color: #0c4ca3;
  text-align: center;
}
</style>
