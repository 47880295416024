<template>
  <div id="app">
    <v-app>
    <router-view :key="$route.fullPath" />
    <mainFooter />
    </v-app>
  </div>
</template>
<script>
import mainFooter from "@/components/mainFooter";
import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    mainFooter,
  },
  computed: {
    ...mapState({
      access_token: (state) => state.Auth.access_token,
    }),
  },
  created() {
    // if (this.access_token) {
    //   this.$store
    //     .dispatch("Auth/getUser", this.access_token)
    //     // eslint-disable-next-line no-unused-vars
    //     .catch((err) => {
    //       if (err.response.status == 401) {
    //         this.$store.dispatch("Auth/clearToken");
    //         this.$router.push("/login");
    //       }
    //     });
    // }
  },
  mounted() {
    this.$store.dispatch("Courses/onGetMenus");
  },
};
</script>

<style lang="scss"></style>
