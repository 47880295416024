import Service from "../service";

const ministry = {
  onGetMinistry() {
    return Service.get(`/ministry`);
  },
  onGetUnitMinistry(ministry) {
    return Service.get(`/unit-ministry`,{
      params:{ministry:ministry}
    });
  },
  onGetUnit(unitMinistry) {
    return Service.get(`/unit/${unitMinistry}`);
  },
  onGetDepartment(unit) {
    return Service.get(`/department/${unit}`);
  },
};

export default ministry;
