import Service from "../service";

const auth = {
  onRegister(data) {
    return Service.post("/auth/register", data);
  },
  onRegisterWC(data) {
    return Service.post("/auth/register-wc", data);
  },
  onLogin(data) {
    return Service.post("/auth/login", data);
  },
  onLogout() {
    return Service.get("/auth/logout");
  },
  onLoginGoogle(data) {
    return Service.post("/auth/google", data);
  },
  onLoginFacebook(data) {
    return Service.post("/auth/facebook", data);
  },
  onEditProfile(data) {
    return Service.post("/auth/editProfile-nrct", data);
  },
  onForgotPassword(data) {
    return Service.post("/auth/forget-password", data);
  },
  getUser(accessToken) {
    return Service.get("/auth/user", {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },
  userConfirmation(code) {
    return Service.post("/auth/email-confirmation", {
      code,
    });
  },
  onChangePassword(model) {
    return Service.post("/auth/change-password", model);
  },
  getUserNRCT(id) {
    return Service.get(`/auth/user-nrct/${id}`);
  },
};

export default auth;
