<template>
  <div id="sideMenu" class="mt-5">
    <div class="text-left" shadow>
      <p style="font-size: 24px" class=""><b>หมวดหมู่</b></p>
      <div class="side-menu-border px-2 py-2">
        <button
          @click="categorytClick('')"
          class="btn btn-outline-white btn-block side-menu text-left pl-0 btnHover"
        >
          คอร์สเรียนทั้งหมด
        </button>
      </div>
      <div class="side-menu-border px-2 py-2 mt-2">
        <button
          v-for="item in category"
          :key="item.id"
          @click="categorytClick(item.id)"
          href="#"
          class="btn btn-outline-white btn-block side-menu text-left pl-0 btnHover"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "sideMenu",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      category: (state) => state.Category.category,
    }),
  },
  created() {
    this.getCategory();
  },
  methods: {
    async getCategory() {
      await this.$store.dispatch("Category/getCategory");
    },
    async categorytClick(id) {
      this.isLoading = true;
      await this.$store.dispatch("Courses/getAllCourse", {
        category: id,
        keyword: null,
      });
      this.isLoading = false;
    },
  },
};
</script>
<style>
@media screen and (max-width: 992px) {
  #sideMenu {
    display: none;
  }
}

/*button.side-menu:hover {*/
/*  background-color: #2c3143; !* Green *!*/
/*  color: white;*/
/*}*/
</style>
