<template>
  <b-card
    border-variant="secondary"
    :title="header"
    :img-src="image"
    img-alt="Image"
    img-top
    tag="article"
    @click="goCourse(id)"
    class="card-article card-course"
    style="border-radius: 15px; height: 100%; overflow: hidden"
  >
    <b-card-text class="short-desc" v-html="content"></b-card-text>
  </b-card>
</template>

<script>
export default {
  props: ["header", "content", "image", "id"],
  methods: {
    async goCourse(id) {
      const path = `/courses`;
      await this.$router.push({ path: path, query: { id: id } });
    },
  },
};
</script>

<style>
.card-title {
  font-size: 18px;
  font-weight: bold;
}
</style>
